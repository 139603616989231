let context="/" + window.location.href.split("/")[3].replace("\#","");
context = context == "/" ?"":context;

const API = {
    context,
    //获取券模板c列表
    //demo : getUpTagPage: context + "/userPortrait/getUpTagPage.mob",
     //host: "http://localhost:9092"+context,
    host: ""+context
}

export default API;
