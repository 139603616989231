<template>
  <div class="container">
    <div class="weui-msg">
      <img src="../../public/img/mes-top-bg.png" width="100%" alt="">
      <div class="weui-msg__icon-area">
        <i class="weui-icon-warn weui-icon_msg"></i>
      </div>
      <div class="weui-msg__text-area">
        <h2 class="weui-msg__title">{{errorMessage}}</h2>
        <p class=""></p>
      </div>
      <img src="../../public/img/mes-bottom-bg.png" width="100%" alt="">
    </div>
  </div>

</template>

<script>

    export default {
      beforeCreate(){
        let meg = this.$route.query.meg;
        this.errorMessage=meg;
      },
      data() {
      },
        name: "failPage"
    }
</script>

<style scoped>

</style>
