<template>
  <div class="container">
    <div class="weui-msg">
      <img src="../../public/img/mes-top-bg.png" width="100%" alt="">
      <div class="weui-msg__icon-area">
        <i class="weui-icon-success weui-icon_msg"></i>
      </div>
      <div class="weui-msg__text-area">
        <h2 class="weui-msg__title">开票申请受理成功</h2>
        <p class="" style="color:#999;font-size:14px">请稍后查收邮件<br/>或在微信-我的-卡包-我的票券查看</p>
      </div>
      <img src="../../public/img/mes-bottom-bg.png" width="100%" alt="">
    </div>
  </div>
</template>

<script>
    export default {
      name: "successPage"
    }
</script>

<style scoped>

</style>
