<template>
  <div>
    <div class="container">
          <a-form class="form"  layout="horizontal" id="form" :form="editTitleForm">
            <div class="weui-cells weui-cells_form form-content" style="text-align: left" >
              <div v-bind:key="prop.title" v-for="prop in orderRow" class="order">
                <div class="weui-cell">
                  <div class="weui-cell__hd"><label class="weui-label" style="text-align: left">{{prop.title}}</label></div>
                  <div class="weui-cell__bd" style="text-align: left" v-for="(index,data) in orderData" v-bind:key="orderData.wxOrderId" v-if="prop.dataIndex == data">
                    {{index}}
                  </div>
                </div>
              </div>
              <div v-bind:key="prop.title" v-for="prop in tableRow">
                <a-form-item class="inputCols" :label="prop.title" :labelCol="{span:4}" :wrapperCol="{span:12}" v-if="'orderId' === prop.dataIndex" >
                  <a-input class="weui-cell__hd"  placeholder="请输入正确的订单号"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                </a-form-item>
              </div>
            </div>
          </a-form>
      <div>
        <div id="apply" class="invoice-bt" v-on:click="submitForm()">申请重新开票</div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import api from '../api/api';
  import AFormItem from "ant-design-vue/es/form/FormItem";
  import AForm from "ant-design-vue/es/form";
  import weui from 'weui.js'
  import 'weui'


  export default {
    components: {AFormItem},
    props: [],
    beforeCreate(){
      let orderId = this.$route.query.orderId;
      let openId = this.$route.query.openId;
      let tid = this.$route.query.tid;


      this.orderId=orderId;
      this.openId=openId;
      this.tid = tid;
      this.editTitleForm = this.$form.createForm(this);
      console.log(orderId);
      console.log(openId);
    },
    data() {
      return{
        targetGrpFetching:false,
        pushNoticeVisible:false,
        timerVisible:true,
        titleType:"1",
        company_visible:true,
        group_visible:true,
        editTitle:"编辑公告",
        PK:"tid",
        queryParam:{},
        tableRow: [
          {title: "订单信息",dataIndex:"orderInfo",scopedSlots: {customRender: "orderInfo"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "",dataIndex:"line",scopedSlots: {customRender: "line"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "订单号",dataIndex:"orderId",scopedSlots: {customRender: "orderId"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:"订单号不能为空"}]}},
        ],
        orderRow:[
          {title: "订单编号",dataIndex:"wxOrderId",scopedSlots: {customRender: "wxOrderId"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "PDF_URL",dataIndex:"pdf_url",scopedSlots: {customRender: "pdf_url"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "FP_URL",dataIndex:"fp_url",scopedSlots: {customRender: "fp_url"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "购买方名称",dataIndex:"gmf_mc",scopedSlots: {customRender: "gmf_mc"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "客户手机",dataIndex:"gmf_sjh",scopedSlots: {customRender: "gmf_sjh"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "客户邮箱",dataIndex:"gmf_dzyx",scopedSlots: {customRender: "gmf_dzyx"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "备注",dataIndex:"bz",scopedSlots: {customRender: "bz"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
        ],
        tableData:[],
        orderData:[],
        editNoticeVisible:false,
        grpData:[],
        searchChoose: {now: "", data: []},
        page:{current:1,pageSize:10},
        host:api.host,
        api: {
          getInvoiceInfo:"/invoice/getInvoiceInfo",
          reInvoice:"/invoice/reInvoice",
        }
      }
    },

    computed:{
      titltTypeSelected(){
        const{ titleType } = this;
        return titleType
      }
    },
    mounted() {
      this.getOrderInfo();
    },
    methods: {
      getOrderInfo(){
        let param = {};
        param["wxOrderId"] = this.orderId;
        axios.post(this.host + this.api.getInvoiceInfo+ "?current=" + this.page.current + "&size=" + this.page.pageSize, param).then(result => {
          this.orderData = result.data.result;
        });
      },
      saveInvoiceInfo(){

      },
      submitForm() {
        let dom = this;
        this.$confirm({
          title: '是否确认开票',
          content: '请确认发票信息进行开票',
          onOk() {
            dom.editTitleForm.validateFields((err, values) => {
              console.log(values)
              if (!err) {
                  axios.post(dom.host+dom.api.reInvoice,values).then(result=>{
                    dom.orderData = result.data;
                        console.log(result)
                  })
              }
            });
          },
          onCancel() {
          },
        });
      },
    },
  }
</script>

<style scoped>


</style>
