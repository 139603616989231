<template>
  <div class="container">
    <div class="title-list" v-for="titleItem in tableData" v-bind:key="titleItem.tid">

      <a class="invoice-title" >
        <div class="invoice-name" v-on:click="inputInvoiceTitle(titleItem.tid)">{{titleItem.title}}</div>
        <div class="line-container">
          <span class="line"></span>
        </div>
        <div v-if="titleItem.type == 0" class="invoice-type invoice-type-person">个人</div>

        <div v-if="titleItem.type == 1" class="invoice-type invoice-type-company">企业</div>

        <div v-if="titleItem.type == 2" class="invoice-type invoice-type-group">社会团体</div>
      </a>
    </div>
    <div class="footer">
      <div class="logo"><img src="../../public/img/logo.png" width="40" alt=""></div>
      <div class="txt">自2017年7月1日起，所有企业抬头的普通发票都必须填写纳税人识别号</div>
    </div>
    <div class="invoice-bt" id="chooseOther" v-on:click="inputInvoiceTitle()">
      使用其他抬头开票
    </div>
  </div>
</template>

<script>


    import axios from 'axios';
    import api from '../api/api';
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import styles from "../../public/css/common.css"


    export default {
      components: {AFormItem},
      props: [],
      beforeCreate(){
        let orderId = this.$route.query.orderId;
        let openId = this.$route.query.openId;
        this.orderId=orderId;
        this.openId=openId;
        console.log(orderId);
        console.log(openId);
      },
      data() {
        return{
          targetGrpFetching:false,
          pushNoticeVisible:false,
          timerVisible:true,
          isInsert:false,
          grpVisible:false,
          editTitle:"编辑公告",
          PK:"tid",
          queryParam:{},
          tableRow: [
            {title: "抬头信息",dataIndex:"title",scopedSlots: {customRender: "title"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:"标题为空或过长"}]}},
            {title: "类型",dataIndex:"type",scopedSlots: {customRender: "type"}, options:{validateTrigger: 'blur',rules: [{required:true,message:"请输入内容"}]}},
            {title: 'Action',key: 'operation',fixed: 'right',width: 150, editHidden:true, scopedSlots: {customRender: 'action'}}
          ],
          tableData:[],
          pushData:[],
          editNoticeVisible:false,
          searchTargetGrpData:[],
          grpData:[],
          searchChoose: {now: "", data: []},
          page:{current:1,pageSize:10},
          host:api.host,
          api: {
            getInvoiceTitle: "/invoice/invoice/apiInvoiceTitle/getRecentInvoiceTitle",
          }
        }
      },

      mounted() {
        this.getList();
      },
      methods: {
        getList(){
          let param = {};
          param["openId"] = this.openId;
          console.log(param)
          axios.post(this.host + this.api.getInvoiceTitle+ "?current=" + this.page.current + "&size=" + this.page.pageSize, param).then(result => {
            console.log(result)
            this.tableData = result.data.result;
            console.log(result.data.result)
          });
        },
        inputInvoiceTitle(tid){
          console.log(tid)
          this.$router.push({
            path:'../inputInvoiceTitle',
            query:{
              orderId:this.orderId,
              openId:this.openId,
              tid:tid
            }
          })
        }
      },
    }

</script>

<style scoped>

</style>
