import Vue from "vue";
import VueRouter from "vue-router";
import selectInvoiceTitle from "@/views/selectInvoiceTitle";
import inputInvoiceTitle from "@/views/inputInvoiceTitle";
import successPage from "@/views/successPage";
import failPage from "@/views/failPage";
import reInvoice from "@/views/reInvoice";
import invoiceList from "@/views/invoiceList";

Vue.use(VueRouter);

const routes = [
  { path: "/selectInvoiceTitle", component: selectInvoiceTitle },
  { path: "/inputInvoiceTitle", component: inputInvoiceTitle },
  { path: "/successPage", component: successPage },
  { path: "/reInvoice", component: reInvoice },
  { path: "/invoiceList", component: invoiceList },
  { path: "/failPage", component: failPage },
  { path: "/", component: successPage },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
