<template>
  <div>
    <div style="width: 100%;margin:0 0 20px 0;" align="center">
      <a-input style="width: 35%; margin-right: 20px;" v-model="queryParam[orderId]"></a-input>
      <a-button type="primary" v-on:click="getList()">查询</a-button>
    </div>
    <a-table :rowKey="PK" :columns="tableRow" :dataSource="tableData" :scroll="{ x: 1500 }">
      <div slot="pdfUrl" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title" v-if="text && text.length > 30">
            <span>{{ text }}</span>
          </template>
          {{ text && text.length > 30 ? text.substr(0, 30) + "..." : text }}
        </a-tooltip>
      </div>
    </a-table>
  </div>
</template>

<script>
import axios from 'axios';
import api from '../api/api';
import AFormItem from "ant-design-vue/es/form/FormItem";
import styles from "../../public/css/common.css"
export default {
  beforeCreate() {
    this.invoiceForm = this.$form.createForm(this);
  },
  props: [],
  data() {
    return {
      queryParam: {},
      orderId: "orderId",
      PK: "wxOrderId",
      tableRow: [
        { title: "订单编码", dataIndex: "wxOrderId", scopedSlots: { customRender: "wxOrderId" }, options: { validateTrigger: 'blur', rules: [{ required: true, message: "请输入周期编码" }] } },
        { title: "购买方邮箱", dataIndex: "gmfDzyx", scopedSlots: { customRender: "gmfDzyx" }, options: { validateTrigger: 'blur', rules: [{ required: true, message: "请输入周期名称" }] } },
        { title: "价税合计", dataIndex: "jshj", scopedSlots: { customRender: "jshj" }, options: { validateTrigger: 'blur', rules: [{ required: true, message: "请输入时间" }] } },
        { title: "合计金额", dataIndex: "hjje", scopedSlots: { customRender: "hjje" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "合计税额", dataIndex: "hjse", scopedSlots: { customRender: "hjse" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "发票号码", dataIndex: "fpHm", scopedSlots: { customRender: "fpHm" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "发票代码", dataIndex: "fpDm", scopedSlots: { customRender: "fpDm" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "校验码", dataIndex: "jym", scopedSlots: { customRender: "jym" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "发票地址", dataIndex: "pdfUrl", scopedSlots: { customRender: "pdfUrl" }, options: { validateTrigger: 'blur', rules: [] } },
        { title: "开票错误原因", dataIndex: "failReason", scopedSlots: { customRender: "failReason" }, options: { validateTrigger: 'blur', rules: [] } },
      ],
      tableData: [],
      host: api.host,
      api: {
        getList: "/invoice/getInvoiceByOrderId",
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let param = {};
      for (var key in this.queryParam) {
        param[key.replace(/([A-Z])/g, "_$1").toUpperCase()] = this.queryParam[key];
      }
      console.log(this.queryParam['orderId'] + "QQQQQQQQQQQQQQQQQQQQQQQ")
      axios.post(this.host + this.api.getList, param).then(result => {
        console.log(result.data.data.records)
        this.tableData = result.data.data.records;
      });
    },
  },


}




</script>

<style scoped></style>
