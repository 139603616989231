<template>
  <div>
    <div class="container">
        <a-row>
          <a-col :xs="{span:24}" :md="{push:8,span:8}">
              <a-form class="form"  layout="horizontal" id="form" :form="editTitleForm">
                <div class="weui-cells weui-cells_form form-content" style="text-align: left" >
                  <div v-bind:key="prop.title" v-for="prop in orderRow" class="order">
                    <a-form-item hidden >
                      <a-input v-decorator="['tid',{}]"/>
                    </a-form-item>
                    <div class="weui-cell">
                      <div class="weui-cell__hd"><label class="weui-label" style="text-align: left">{{prop.title}}</label></div>
                      <div class="weui-cell__bd" style="text-align: left" v-for="(index,data) in orderData" v-bind:key="orderData.wxOrderId" v-if="prop.dataIndex == data">
                        {{index}}
                      </div>
                    </div>
                  </div>
                  <div class="line-container">
                    <span class="line"></span>
                  </div>
                  <div class="group_tips" >
                    港澳台的纳税人识别号，请选择“社会团体”类型
                  </div>
                    <div v-bind:key="prop.title" v-for="prop in tableRow">
                      <a-form-item hidden >
                        <a-input v-decorator="['tid',{}]"/>
                      </a-form-item>

                      <a-form-item class="inputCols"  :label="prop.title" :wrapperCol="{flex:20,sm:{span:16,offset:2},pull:6}" v-if="'type' === prop.dataIndex" >
                        <a-radio-group :default-value=titltTypeSelected @change="selectType" size="small" >
                          <a-radio-button  value="1">
                            企业
                          </a-radio-button>
                          <a-radio-button  value="0">
                            个人
                          </a-radio-button>
                          <a-radio-button  value="2">
                            社会团队
                          </a-radio-button>
                        </a-radio-group>
                      </a-form-item>
                      <a-form-item class="inputCols" :label="prop.title" :labelCol="{pull:0}" :wrapperCol="{sm:{span:16},pull:6}" v-if="'title' === prop.dataIndex" >
                        <a-input class="weui-cell__hd"  placeholder="请输入正确的抬头信息"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item class="inputCols" :label="prop.title" v-if="'identfiy' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="company_visible"  >
                        <a-input placeholder="请输入正确的纳税人识别号"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'tel' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}">
                        <a-input placeholder="请输入正确的手机号"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'email' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}">
                        <a-input placeholder="请输入正确的电子邮箱"   v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <div class="optional" v-if="'tips' === prop.dataIndex" >
                        <p class="optional-tips">非必填信息（按需填写）</p>
                      </div>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'bankName' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="company_visible" >
                        <a-input placeholder="请输入正确的银行名称"   v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'bankAccount' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="company_visible" >
                        <a-input placeholder="请输入正确的账户"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'companyAddress' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="company_visible" >
                        <a-input placeholder="请输入正确的单位名称"   v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'companyTel' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="company_visible" >
                        <a-input placeholder="请输入正确的单位电话"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'identfiy' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}" :hidden="group_visible"  >
                        <a-input placeholder="请输入正确的纳税人识别号"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                      <a-form-item  class="inputCols" :label="prop.title" v-if="'remarks' === prop.dataIndex" :wrapperCol="{sm:{span:16},pull:6}">
                        <a-input placeholder="备注信息"  v-decorator="[prop.dataIndex,prop.options]"></a-input>
                      </a-form-item>
                  </div>
                  <div class="logo"><img src="../../public/img/logo.png" width="40" alt=""></div>
                  <div class="tips">
                    扫描小票二维码可开票，可到微信-我的-卡包-我的票券查看
                  </div>
                  <div class="invoice-bt" id="apply" v-on:click="submitForm()">申请开票</div>
                </div>

              </a-form>
          </a-col>
        </a-row>

    </div>

    <div id="toast" style="display:none">
      <div class="weui-mask_transparent"></div>
      <div class="weui-toast">
        <i class="weui-icon-warn weui-icon_toast"></i>
        <p class="weui-toast__content" id="toastMes"><span></span></p>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import api from '../api/api';
  import AFormItem from "ant-design-vue/es/form/FormItem";
  import AForm from "ant-design-vue/es/form";
  import weui from 'weui.js'
  import 'weui'


  export default {
    components: {AFormItem},
    props: [],
    beforeCreate(){
      let orderId = this.$route.query.orderId;
      let openId = this.$route.query.openId;
      let tid = this.$route.query.tid;
      this.orderId=orderId;
      this.openId=openId;
      this.tid = tid;
      this.editTitleForm = this.$form.createForm(this);
      console.log(orderId);
      console.log(openId);
    },
    data() {
      return{
        targetGrpFetching:false,
        pushNoticeVisible:false,
        timerVisible:true,
        titleType:"0",
        company_visible:true,
        group_visible:true,
        editTitle:"编辑公告",
        PK:"tid",
        queryParam:{},
        tableRow: [
          {title: "订单信息",dataIndex:"orderInfo",scopedSlots: {customRender: "orderInfo"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "",dataIndex:"line",scopedSlots: {customRender: "line"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "开票类型",dataIndex:"type",scopedSlots: {customRender: "type"}, options:{validateTrigger: 'blur',rules: [{required:false,message:""}]}},
          {title: "发票抬头",dataIndex:"title",scopedSlots: {customRender: "title"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:"抬头不能为空"}]}},
          {title: "纳税人识别号",dataIndex:"identfiy",scopedSlots: {customRender: "identfiy"}, options:{validateTrigger: 'blur',rules: [{required:false,pattern:/^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/, message:"纳税人识别码不正确"}]}},
          {title: "手机号码",dataIndex:"tel",scopedSlots: {customRender: "tel"},options:{validateTrigger: 'blur',rules: [{required:true,max:30,pattern:/^1[3456789]\d{9}$/, message:"手机号格式错误"}]}},
          {title: "电子邮箱",dataIndex:"email",scopedSlots: {customRender: "email"}, options:{validateTrigger: 'blur',rules: [{required:true,pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,message:"电子邮箱格式不正确"}]}},
          {title: "tips",dataIndex:"tips",scopedSlots: {customRender: "tips"},options:{validateTrigger: 'blur',rules: [{required:false,max:30, message:""}]}},
          {title: "开户银行",dataIndex:"bankName",scopedSlots: {customRender: "bankName"},options:{validateTrigger: 'blur',rules: [{required:false,max:30, message:""}]}},
          {title: "银行账号",dataIndex:"bankAccount",scopedSlots: {customRender: "bankAccount"}, options:{validateTrigger: 'blur',rules: [{required:false,message:""}]}},
          {title: "单位地址",dataIndex:"companyAddress",scopedSlots: {customRender: "companyAddress"},options:{validateTrigger: 'blur',rules: [{required:false,max:30, message:""}]}},
          {title: "单位电话",dataIndex:"companyTel",scopedSlots: {customRender: "companyTel"}, options:{validateTrigger: 'blur',rules: [{required:false,message:""}]}},
          {title: "备注说明",dataIndex:"remarks",scopedSlots: {customRender: "remarks"},options:{validateTrigger: 'blur',rules: [{required:false,max:30, message:""}]}},
        ],
        orderRow:[
          {title: "订单编号",dataIndex:"wxOrderId",scopedSlots: {customRender: "orderId"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "订单日期",dataIndex:"createTime",scopedSlots: {customRender: "createTime"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
          {title: "订单金额",dataIndex:"orderProductMoney",scopedSlots: {customRender: "orderMoney"},options:{validateTrigger: 'blur',rules: [{required:true,max:30, message:""}]}},
        ],
        tableData:[],
        orderData:[],
        editNoticeVisible:false,
        grpData:[],
        searchChoose: {now: "", data: []},
        page:{current:1,pageSize:10},
        host:api.host,
        api: {
          getInvoiceTitleInfo: "/invoice/invoice/apiInvoiceTitle/getInvoiceTitleInfo",
          getApplyInfo:"/invoice/invoice/apiInvoiceApply/getInvoiceApplyByWxOrderId",
          saveInvoice:"/invoice/saveWebInvoiceInfo",
        }
      }
    },

    computed:{
      titltTypeSelected(){
        const{ titleType } = this;
        console.log("QQQQQQQ"+titleType)
        if (titleType == '1'){
          this.company_visible = false;
        } else if (titleType == '2') {
          this.group_visible = false;
        }
        return titleType
      }
    },
    mounted() {
      this.getList();
      this.getOrderInfo();
    },
    methods: {
      getList(){
        let param = {};
        param["tid"] = this.tid;
        axios.post(this.host + this.api.getInvoiceTitleInfo+ "?current=" + this.page.current + "&size=" + this.page.pageSize, param).then(result => {
          console.log(result)
          this.tableData = result.data.result;
          if (result.data.result.type) {
            this.titleType=result.data.result.type;
          }
          if (result.data.result && result.data.result.type == '1'){
            this.company_visible = false;
          } else if (result.data.result && result.data.result.type == '2') {
            this.group_visible = false;
          }
          setTimeout(() => this.editTitleForm.setFieldsValue(this.tableData), 0);
        });
      },
      getOrderInfo(){
        let param = {};
        param["wxOrderId"] = this.orderId;
        axios.post(this.host + this.api.getApplyInfo+ "?current=" + this.page.current + "&size=" + this.page.pageSize, param).then(result => {
          this.orderData = result.data.result;
        });
      },
      saveInvoiceInfo(){

      },
      submitForm() {
        let dom = this;
        this.$confirm({
          title: '是否确认开票',
          content: '请确认发票信息进行开票',
          onOk() {
            dom.editTitleForm.validateFields(async (err, values) => {
              if (!err) {
                  let param = values;
                  param["type"] = dom.titleType;
                  param.orderIds = [dom.orderId];
                  param.openid = dom.openId;
                  console.log(param);
                  axios.post(dom.host+dom.api.saveInvoice,param).then(result=>{
                    console.log(result)
                        if(result.data.code == '0'){
                          window.location.href=result.data.data.auth_url;
                        }else{
                          dom.$router.push({
                            path:'../failPage',
                            query:{meg:result.data.message},
                          })
                        }
                  })
              }
            });
          },
          onCancel() {
          },
        });
      },
      handleFormTypeChanged(e){
        console.log("QQQQQQQQQQQQQQQQQQQQQQQQ"+e.target.value)
      },
      selectType(e){
        let dom = this;
        if ( e.target.value === '1'){
          dom.titleType='1'
          this.group_visible=true;
          this.company_visible = false;
        } else if (e.target.value ==='2') {
          dom.titleType='2'
          this.company_visible=true;
          this.group_visible = false;
        }else {
          dom.titleType='0'
          this.group_visible=true;
          this.company_visible = true;
        }
      },
    },
  }
</script>

<style scoped>
  .inputCols{
    display: -webkit-flex;
    display: flex;
    padding: 0px 0% 0% 1%;
    align-items: center;
    background: #fff;
    margin: 0%;
    justify-content: center;
  }
  .inputChild>*{
    flex:16;
  }
  .inputCols .label{
   flex:16;
 }

  .inputCols  .ant-form-item-control-wrapper{
    flex: 16;
  }

</style>
